<template>
  <div class="exitBtnWrap" :class="{'exitBtnWrap--disable':disabled}" @click="handleClick">
    <svg-icon name="arrow-left" height="100%" width="100%"></svg-icon>
  </div>
</template>

<script>
import SvgIcon from "../SvgIcon/svgIcon";
export default {
  name: "ExitBtn",
  components: {SvgIcon},
  props:{
    handler:{
      required:false
    },
    disabled:{
      required: false,
      default: false
    }
  },
  data(){
    return {

    }
  },
  methods:{
    handleClick(){
      if(this.$route.query.exitTo){
        this.$router.push({
          name:this.$route.query.exitTo
        })
        return;
      }

      if(this.$props.handler) {
        this.$props.handler();
        return;
      }

      this.$router.push({
        name:'contractIndex'
      })
    }

  }
}
</script>

<style scoped>
.exitBtnWrap{
  width: 20px;
  height:20px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
  flex-shrink: 0;
}

.exitBtnWrap:hover{
  background: rgba(0,0,0,.15);
  border-radius: 5px;
}
.exitBtnWrap--disable{
  color: #ccc;
}
</style>
