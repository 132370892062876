<template>
  <li class="contract-menu-item help-list-item">
    <span>
      帮助
    </span>
    <ul v-show="helpMenuVisible" class="dropdown-menu" >
      <li @click="goToHelpCenter"
          class="dropdown-menu-item">
        <i></i><span>前往帮助中心</span>
      </li>
      <li
          @click="contactService"
          class="dropdown-menu-item">
        <i></i><span>联系客服</span>
      </li>
    </ul>

    <Mask
    :display="codeTipVisible ? 'block':'none'"
    :locate-body="true"
    @clickVacancy="codeTipVisible = false"
    >
      <div class="service-2DCode insetCenter">
          <img class="img2D" src="../../assets/images/wechat-service-other.jpg"/>
          <p style="text-align: center">如有任何使用问题请联系秘塔客服</p>
      </div>
    </Mask>
  </li>
</template>

<script>
import Mask from "../universalUI/Mask";
import {colorLog} from "../../util";
export default {
  name: "HelpListItem",
  components: {Mask},
  props:{
    helpMenuVisible:{
      required:true
    },
  },
  data(){
    return {
      codeTipVisible:false,
    }
  },
  methods:{
    goToHelpCenter(){
      this.$router.push({
        name:'helpCenter'
      })
    },
    contactService(){
      colorLog('contactService')
      this.codeTipVisible = true;
    }
  }
}
</script>

<style scoped>
.service-2DCode{
  width: 300px;
  text-align: center;
  padding: 10px;
  background: white;
  border-radius: 4px;
}
.img2D{
  width: 100%;
}
</style>
